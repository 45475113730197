import React from "react"
import challengeEP from "../img/releases/challenge-ep-cover.jpg"
import espero from "../img/releases/espero-cover.png"

const images = {
  espero,
  challengeEP,
}

export const Cover = ({ title, id }) => {
  return <img src={images[id]} alt={`${title} Cover`} />
}
