import React from "react"
import { Layout } from "../components/Layout"
import { Cover } from "../components/Cover"
import { graphql, Link } from "gatsby"
import logo from "../img/tuedelbeats-logo.png"

export default function Release({ data }) {
  const release = data.markdownRemark
  const {
    id,
    title,
    // spotify_embed,
    bandcamp_href,
    spotify_href,
    apple_music_href,
    itunes_href,
    amazon_music_href,
    deezer_href,
    tidal_href,
  } = release.frontmatter
  return (
    <Layout isMinimal>
      <section className="section">
        <div className="container">
          <div className="columns is-mobile">
            <div className="column is-narrow">
              <Link to="/" title="back to tuedelbeats.de">
                <img src={logo} width="60" alt="Tüdel Beats Logo" />
              </Link>
            </div>

            <div className="column">
              <h1 className="title">{title}</h1>
            </div>
          </div>

          <div className="columns">
            <div className="column is-narrow">
              <Cover id={id} title={title} />
              {/* {spotify_embed && spotify_embed !== "" && (
                <div className="spotify-embed-container mb-2">
                  <iframe
                    title="spotify-embed"
                    src={spotify_embed}
                    width="100"
                    height="220"
                    frameborder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                  ></iframe>
                </div>
              )} */}

              {spotify_href && spotify_href !== "" && (
                <a
                  href={spotify_href}
                  className="button is-large is-fullwidth mb-2 is-primary"
                >
                  Spotify
                </a>
              )}

              {apple_music_href && apple_music_href !== "" && (
                <a
                  href={apple_music_href}
                  className="button is-large is-fullwidth mb-2 is-primary"
                >
                  Apple Music
                </a>
              )}

              <a
                href={bandcamp_href}
                className="button is-large is-fullwidth mb-2 is-primary"
              >
                Bandcamp
              </a>

              {itunes_href && itunes_href !== "" && (
                <a
                  href={itunes_href}
                  className="button is-large is-fullwidth mb-2 is-primary"
                >
                  iTunes
                </a>
              )}

              {amazon_music_href && amazon_music_href !== "" && (
                <a
                  href={amazon_music_href}
                  className="button is-large is-fullwidth mb-2 is-primary"
                >
                  Amazon Music
                </a>
              )}

              {deezer_href && deezer_href !== "" && (
                <a
                  href={deezer_href}
                  className="button is-large is-fullwidth mb-2 is-primary"
                >
                  Deezer
                </a>
              )}

              {tidal_href && tidal_href !== "" && (
                <a
                  href={tidal_href}
                  className="button is-large is-fullwidth is-primary"
                >
                  Tidal
                </a>
              )}
            </div>
            <div
              className="column is-half release-text"
              dangerouslySetInnerHTML={{ __html: release.html }}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      html
      frontmatter {
        id
        title
        bandcamp_href
        spotify_embed
        spotify_href
        apple_music_href
        itunes_href
        amazon_music_href
        deezer_href
        tidal_href
      }
    }
  }
`
